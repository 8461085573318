import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Col,
  Row,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import HttpErrorHandler from '../../components/http-error-handler';
import withAPI from '../../hocs/with-api';

import Spinner from '../../components/spinner';
import CarrierForm from '../../components/carrier-form';
import ButtonWithIcon from '../../components/button-with-icon';

class EditCarrier extends PureComponent {
  constructor() {
    super();
    this.state = {
      carrier: null,
    };
  }

  async componentDidMount() {
    const { httpRequest, history, match: { params: { id } } } = this.props;
    const response = await httpRequest({
      method: 'get',
      url: `carriers/${id}`,
    }).catch(() => {
      history.push('/dashboard');
    });
    const carrier = _.get(response, 'data');
    if (carrier) {
      this.setState({
        carrier,
      });
    }
  }

  onHold = async (carrierId) => {
    const { httpRequest } = this.props;
    const response = await httpRequest({
      method: 'post',
      url: `carriers/hold/${carrierId}`,
    });
    const responseCarrier = _.get(response, 'data');
    if (responseCarrier) {
      this.setState({
        carrier: responseCarrier,
      });
    }
  }

  onUnhold = async (carrierId) => {
    const { httpRequest } = this.props;
    const response = await httpRequest({
      method: 'post',
      url: `carriers/unhold/${carrierId}`,
    });
    const responseCarrier = _.get(response, 'data');
    if (responseCarrier) {
      this.setState({
        carrier: responseCarrier,
      });
    }
  }

  onDelete = async (carrierId) => {
    const { httpRequest, history, t } = this.props;
    const deleted = await httpRequest({
      method: 'delete',
      url: `carriers/${carrierId}`,
    });
    if (deleted) {
      history.push({
        pathname: _.get(this.props, 'location.state.prevPath', '/carriers'),
        flash: {
          type: 'success',
          message: t('carriers.deleted'),
        },
      });
    }
  }

  updateCarrier = async ({
    name,
    sapId,
    phone_number,
    send_sms_notification,
    description,
    priceIndex,
  }) => {
    const {
      t, httpRequest, match: { params: { id } }, history,
    } = this.props;
    const patched = await httpRequest({
      method: 'patch',
      url: `carriers/${id}`,
      data: {
        name,
        phone_number,
        send_sms_notification,
        sap_id: sapId,
        description,
        price_index: priceIndex || '1.0',
      },
    });
    if (patched) {
      history.push({
        pathname: _.get(this.props, 'location.state.prevPath', '/carriers'),
        flash: {
          type: 'success',
          message: t('carriers.edited'),
        },
      });
    }
  }

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { carrier } = this.state;
    const {
      t,
      httpGetLoading,
      httpGetDone,
      httpPatchFailed,
      httpPatchFailReason,
      httpDeleteFailed,
      httpDeleteFailReason,
      httpPatchLoading,
      httpDeleteLoading,
    } = this.props;
    return (
      <>
        <Col md="12">
          <Row className="with-back-button">
            <p className="lf-main-text form-name-text">{ t('carriers.edit') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
        </Col>
        {
          httpGetLoading && <Spinner />
        }
        {
          httpGetDone && carrier
          && (
            <Col md="12">
              {
                httpPatchFailed && httpPatchFailReason
                && <HttpErrorHandler {...httpPatchFailReason} />
              }
              {
                httpDeleteFailed && httpDeleteFailReason
                && <HttpErrorHandler {...httpDeleteFailReason} />
              }
              <CarrierForm
                isConfirmButtonLoading={httpPatchLoading}
                isDeleteButtonLoading={httpDeleteLoading}
                t={t}
                inEditMode
                carrier={carrier}
                onSubmit={this.updateCarrier}
                onDelete={this.onDelete}
                onHold={this.onHold}
                onUnHold={this.onUnhold}
              />
            </Col>
          )
        }
      </>
    );
  }
}

EditCarrier.propTypes = {
  httpGetLoading: PropTypes.bool.isRequired,
  httpPatchLoading: PropTypes.bool,
  httpGetDone: PropTypes.bool.isRequired,
  httpDeleteLoading: PropTypes.bool,
  httpDeleteDone: PropTypes.bool,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpPatchFailed: PropTypes.bool,
  httpPatchFailReason: PropTypes.object,
  httpDeleteFailed: PropTypes.bool,
  httpDeleteFailReason: PropTypes.object,
};

EditCarrier.defaultProps = {
  httpPatchFailed: false,
  httpPatchFailReason: undefined,
  httpDeleteFailed: false,
  httpDeleteFailReason: undefined,
  httpDeleteLoading: false,
  httpDeleteDone: true,
  httpPatchLoading: false,
};
export default compose(
  withTranslation(),
  withRouter,
  withAPI,
)(EditCarrier);
